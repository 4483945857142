﻿.searchBandFullWidth {
    background-color: $lightBlue;
}
.searchBar {
    width: 100%;
    padding: 25px 0;
    display: block;
    float: left;

    p {
        color: $color_text;
        margin-bottom: 1.0rem;
        text-align: center;
        @include responsive-font(3vw, 18px, 30px);

        @media(min-width: 768px) {
            margin-bottom: 0;
            text-align: left;
        }
    }

    .input-group {
        width: 100%;
    }

    @media(min-width: 768px) {
        display: flex;
    }
}


//napitNew is only a temporary prefix and should be removed when the final product is ready for roll out
.napitNew {
    .container {
        margin-bottom: 0;

        @media (min-width: 768px) and (max-width: 1199px) {
            width: 100%;
        }
    }

    #header {
        background: none;
        background-color: $darkBlue;
        border-top: 0;
        min-height: 1px;

        & > .container {
            margin-bottom: 0;
            display: flex;
            padding: 1.0rem 1.5rem;
            height: 70px;

            @media(min-width: 992px) {
                //height: 100px;
                height: 65px;
            }
        }

        .logo, .userSWitch {
            display: flex;
        }

        .headerSocialIcons {
            margin: 1.0rem 0 0;
            float: right;
            display: block;
            //width: 100%;
        }

        .logo {
            justify-content: flex-start;
            width: 20%;
            padding: 0;

            @media (min-width: 992px) {
                width: calc(100% - 193px);
                padding: 5px 0;
            }

            img {
                max-width: 120px;

                @media (min-width: 992px) {
                    max-width: 188px;
                }
            }

            nav.site-page-nav {
                width: 100%;
                float: right;
                margin-bottom: 0;
                display: none;

                ul.nav-top {
                    margin: 0;
                    float: right;
                    min-height: 1px;

                    li {
                        a, span {
                            color: $color_text;

                            &:hover {
                                color: $darkBlue;
                            }
                        }
                    }

                    li span:hover {
                        color: $color_text;
                    }
                }

                @media (min-width: 992px) {
                    display: block;
                }
            }
        }

        nav {
            float: right;

            li a {
                color: $color_text;
            }

            ul.nav-main {
                margin: 0 10px 8px 0;

                ul.dropdown-menu {
                    box-shadow: 3px 5px 15px 0px #7d7d7d;
                }

                &.mega-menu {
                    margin-bottom: 20px;

                    @media (min-width:992px) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .userSwitch {
            float: left;
            width: 193px;
            margin: 5px 0;

            @media(min-width: 992px) {
                float: right;
            }

            .socicoInner {
                width: 100%;
                text-align: right;

                a {
                    color: $darkBlue;

                    &:hover {
                        text-decoration: none;
                        color: $color_text;
                    }

                    &:focus {
                        text-decoration: none;
                    }
                }
                /*.input-group{
                    float: right;
                }
                #radioBtn a {
                    color: $darkBlue;
                    background-color: $color_text;

                    &.active {
                        text-decoration: none;
                        background-color: $lightBlue;
                        color: $color_text;
                    }
                }*/
            }
        }

        .btn-responsive-nav {
            @media(max-width: 991px) {
                top: 25px;
            }
        }

        @media(min-width: 992px) {
            border-top: 5px solid $lightGrey;
            min-height: 100px;
        }
    }

    .page-home {
        border-top: 5px solid $darkBlue;
    }

    h2 {
        margin: 10px 0 32px;
    }
}
