﻿button{
    outline:none;
    &:focus{
        outline:none;
    }
}
.d-flex {
    display: flex;

    &.align-items-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
}
//search box placement text
.form-control.search{
    font-size: 1.3rem;
}

//member-search.aspx
.row.emergency-callouts {
    margin-bottom: 20px;
    background-color: #fae805;
    padding-bottom: 7px;

    .searchSchemeLogo {
        font-weight: 500;
    }
}