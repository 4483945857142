﻿div.style-switcher{
	background: none repeat scroll 0% 0% #707070;
	width:205px;
	position:fixed;
	top:35px;
	z-index:1002;
	border-radius:0 0 6px;
	right:-205px;
    -webkit-transition: left 0.2s ease-in 0.2s;
	-moz-transition: left 0.2s ease-in 0.2s;
	transition: left 0.2s ease-in 0.2s;
}
div.style-switcher img{
    padding: 5px 5px;

}

div.style-switcher:hover{
    left: 0px !important;
    -webkit-transition: left 0.2s ease-in 0.2s;
	-moz-transition: left 0.2s ease-in 0.2s;
	transition: left 0.2s ease-in 0.2s;
}

div.style-switcher div.style-switcher-mode{
	padding:25px 15px;
	margin-bottom:-33px;
	position:relative;
	z-index:1
}

div.style-switcher div.style-switcher-mode div.options-links a{
	background:0 0;
	border:0
}

div.style-switcher div.style-switcher-mode div.options-links a.active{
	border: none;
	border-color:#3a3a3a;
	border-bottom:1px solid #191c21
}

div.style-switcher div.style-switcher-wrap{
	padding:5px;
	margin:5px;
	position:relative
}

div.style-switcher h4{
	background:#707070;
	color:#FFF;
	padding:5px 0 5px 10px;
	margin:0;
	line-height:30px;
	font-size:13px
}

div.style-switcher h4 a{
	background-color:#707070;
	display:block;
	height:40px;
	position:absolute;
	right:-40px;
	top:0;
	width:40px;
	font-size:17px;
	line-height:38px;
	color:#FFF;
	text-align:center;
	text-decoration:none;
	border-radius:0 6px 6px 0
}

div.style-switcher h4 a i{
	margin-right:5px
}

div.style-switcher h4 a:hover{
	text-decoration:none
}

div.style-switcher h5{
	color:#999;
	margin:0 0 5px;
	padding:0;
	line-height:30px;
	font-size:13px;
	font-weight:400;
	text-transform:none
}

div.style-switcher ul.options{
	list-style:none;
	margin:-3px 0 10px 0;
	padding:0;
	overflow:hidden
}

div.style-switcher ul.options li{
	float:left;
	margin:2px
}

div.style-switcher ul.options li a{
	display:block;
	width:15px;
	height:15px;
	cursor:pointer
}

div.style-switcher ul.options li a.pattern{
	background-repeat:repeat;
	background-position:0 0;
	background-color:transparent
}

div.style-switcher div.color-gradient{
	position:absolute;
	top:14px;
	right:25px;
	font-size:13px
}

html[dir=rtl] div.style-switcher div.color-gradient{
	right:auto;
	left:15px
}

div.style-switcher div.color-gradient input{
	margin-right:5px;
	position:relative;
	top:2px
}

html[dir=rtl] div.style-switcher div.color-gradient input{
	left:7px
}

div.style-switcher div.color-gradient label{
	font-weight:400;
	color:#999;
	font-size:14px
}

div.style-switcher.advanced div.color-gradient{
	top:15px;
	right:20px;
	font-size:14px
}

div.style-switcher div.color-picker{
	display:none;
	margin-bottom:15px
}

div.style-switcher div.options-links{
	padding:0 0 10px
}

div.style-switcher div.options-links a{
	background-color:#000;
	border:1px solid #000;
	color:#ccc;
	font-size:12px;
	margin-bottom:0;
	margin-right:5px;
	padding:5px 12px;
	text-decoration:none
}

div.style-switcher div.layout.options-links a,div.style-switcher div.website-type.options-links a{
	text-align:center;
	min-width:55px;
	display:inline-block
}

div.style-switcher div.options-links a:hover{
	background-color:#111
}

div.style-switcher div.options-links a.active{
	border-color:#ADADAD
}

div.style-switcher hr{
	border-color:#2c2c2c
}

textarea.get-css{
	height:325px;
	width:538px;
	resize:none;
	cursor:text
}

div.style-switcher.advanced{
	width:400px;
	left:-400px
}

div.style-switcher.advanced div.color-picker{
	display:block
}

div.style-switcher.advanced ul.options.colors{
	display:none
}
