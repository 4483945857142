﻿.fullWidthWrap {
    background-color: transparent;
    @media (min-width: 768px) {
        background-color: $darkBlue;
    }
}

/*RF: this style sheet needs sorting - there are dupliactes with the gateway.scss and badly formed selectors*/
.consumer {
    .innerCTA .imgHeader{
        min-height: 20px;
    }
    /*#header {
        min-height: 50px;
        border-top: 0;
        padding: 0;

        /*.container {
            height: auto;
            margin-bottom: auto;
        }*/

        /*nav {
            float: none;

            ul.nav-top {
                min-height: 1px;
                margin: 0;
                padding: 5px 0;

                li a, li span {
                    color: $color_text;
                }
            }

            ul.nav-main {
                margin: 0;
            }
        }*/

        /*.social-icons {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            li {
                margin: 0;
            }
        }*/
   

    /*.site-page-nav {
        margin-bottom: 0;
    }

    .btn-responsive-nav {
        top: 0;

        @media (min-width: 768px) and (max-width: 991px) {
            background-color: $color_text;
        }

        .fa-bars {
            @media (min-width: 768px) and (max-width: 991px) {
                color: $color_shadow;
            }
        }
    }

    #footer .social-icons {
        margin-left: 10px;
    }*/
}
   
    /*.topNav {
        margin: 1.0rem 0 0;
        position: relative;
    }

    .navInnerWrap {
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
        padding: 10px 0 2px;
    }*/

    .pageTitle {
        text-align: center;
        padding: 3.0rem 1.5rem 2.0rem;

        h3 {
            color: $darkBlue;
            font-weight: 700;
            margin-bottom: 1.0rem;
        }

        h4 {
            color: $color_shadow;
        }
    }

.innerCTA {
    padding: 0;
    background-color: $lightGrey;

    span, a {
        display: inline-block;
        width: 100%;
    }

    a {
        &:hover {
            text-decoration: none;
        }

        &.aInline:hover {
            text-decoration: underline;
        }
    }

    .cta-text {
        font-size: 15px;
        text-align: center;
        min-height: 45px;
        color: $color_shadow;
    }

    .imgHeader {
        min-height: 92px;
        margin: 1.5rem 0;
        text-align: center;
    }

    .catHeader {
        @include responsive-font(1.1vw, 20px, 25px);
        margin-bottom: 0;
        color: $lightBlue
    }

    .catDescrip {
        color: $color_shadow;
        font-weight: 400;
    }

    .cta {
        text-align: center;
        margin: 0 0 1.5rem;

        .btn {
            @include responsive-font(1.0vw, 12px, 15px);

            &:hover {
                background-color: $lightBlue;
                color: $color_text;
            }
        }
    }
}


.skewImage {
    height: 100%;
    display: block;
    clip-path: none;
    background-color: $darkBlue;

    .spnImgWrap {
        height: 100%;
        display: block;
        clip-path: none;
    }

    @media (min-width:992px) {
        height: 240px;
        clip-path: polygon(0 0,101% 0%,101% 83%,0 93%);

        .spnImgWrap {
            height: 230px;
            clip-path: polygon(0 0,101% 0%,101% 83%,0 93%);
        }
    }
    /*@media (min-width: 1025px){
        
    }*/
}

/*.imgHeader {
    text-align: center;
    margin: 1.5rem 0;
}*/

    .adjustPadding {
        padding: 0;
        margin-bottom: 1.0rem;

        @media (min-width: 768px) {
            padding: 0 15px;
        }

        /*@media (min-width: 992px) {
            padding: 0 15px;
        }*/
    }
    //microsoft edge
    _:-ms-lang(x), _:-webkit-full-screen, .img-overlay {
        display: none;
    }