﻿.benefits_wrapper {
    margin: 0 -10px;

    .benefits_shell {
        @media (min-width:64em) {
            width: 31.333%;
        }

        @media (min-width:45em) and (max-width:63.999em) {
            width:48%;
        }
        
        width: 100%;

        margin: 0 1% 20px 1%;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border .2s ease-in-out;

        .benefits_item {
            display: block;
            padding: 4px;
            margin-bottom: 20px;
            line-height: 1.42857143;
            position: relative;

            .benefits_block {
                position: relative;

                .thumb-info {
                    img {
                        display: block;
                        max-width: 100%;
                        height: auto;
                    }
                }

                .benefit_logo {
                    text-align: center;
                    margin: 15px 0 0 0;

                    img {
                        width: 100%;
                        max-width: 200px
                    }
                }
            }
        }
    }
}
