﻿.newsletter__sign__up{
    background:#009cde;
    padding:20px 0;
    margin:20px 0 0 0;
    h2, p{
        color:#fff;
    }
    .input-group{
        display:block;
        .form-control{
            &:first-child{
                border-top-right-radius:inherit;
                border-bottom-right-radius:inherit;
            }
            &:last-child{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &.newsletter__sign__up__input{
                margin:0 0 10px 0;
                padding:10px;
                width:100%;
                max-width:350px;
                border-radius: 6px;
                border:0;
                display:inline-block;
            }
             
        }
        .btn-primary{
            color:#009cde;
            background:#fff;            
            position:relative;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            margin:0;
            @media (min-width:40em){
                margin: 0 0 0 15px;
            }
        }           
    }
}
