﻿/*gateway page*/

.gatewayPage {
    min-height: 640px;
    max-width: 1170px;
    margin: auto auto;
    position: relative;

    .centerPage {
        padding: 0;
        @extend %clearfix;
    }

    .deskTabGateway {
        .cat-wrap {
            @extend %clearfix;
        }
    }

    .divWelcome {
        text-align: center;
        padding: 4.0rem 0;

        h1, span {
            color: $darkBlue;
            font-weight: 700;
            @include responsive-font(3vw, 30px, 40px);

            span {
                text-transform: none;
                display: inline-block;
                margin-top: 0;
            }
        }

        h1 {
            margin-bottom: 0;
        }

        span {
            margin: 1.0rem 0;
        }

        img {
            height: 155px;
            width: auto;
            margin: 0.5rem auto;
        }
    }

    .clickThru {
        max-width: 730px;
        float: none;
        margin: 0 auto;
        overflow: overlay;
        text-align: center;

        .callout {
            height: auto;
            margin-bottom: 1.0rem;
            float: left;

            .coInner {
                height: 100%;
                min-height: 210px;
                display: block;
                background-color: $lightBlue;
                border-radius: 6px;
                color: $color_text;

                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 1.0rem 0;

                    &.spnCalloutTxt {
                        text-align: center;
                        padding: 0 1.0rem;
                        margin: 0;
                    }

                    img {
                        margin: 10px auto;
                        width: calc(10vw + 1.0vh + 10vmin);
                        max-width: 100px;

                        &.ico-consumer {
                            width: calc(10vw + 0.1vh + 10vmin);
                        }
                    }
                }

                a {
                    width: auto;
                    color: $color_text;
                    position: relative;
                    display: block;

                    &:hover {
                        color: $darkBlue;
                    }
                }
            }
        }
    }

    .skewImage {
        width: 100%;
        height: 160px;
        clip-path: polygon(0 0,100% 0%,100% 83%,0 93%);
        background-color: $lightBlue;

        .spnImgWrap {
            width: 100%;
            height: 150px;
            clip-path: polygon(0 0,100% 0%,100% 83%,0 93%);
            background-color: $darkBlue;
        }

        img {
            clip-path: none;
            width: 40%;
            width: calc(1.5vw + 1.5vh + 6vmin);
            max-width: 100px;
            margin: 0 auto;
            padding: 1.5rem 0;

            .cls-4 {
                fill: $darkBlue;
            }

            &.ico-consumer {
                width: calc(1.5vw + 1.5vh + 5vmin);
                max-width: 90px;
            }
        }

        @media (min-width: 768px) {
            height: 150px;

            .spnImgWrap {
                height: 140px;
            }
        }

        @media (min-width: 992px) {
            height: 160px;

            .spnImgWrap {
                height: 150px;
            }
        }
    }

    .innerCTA {
        .imgHeader {
            min-height: 92px;
        }
    }

    .info-panel {
        .info-pnl-inner {
            min-height: 100px;
            overflow: hidden;
            position: relative;
            margin: 0 0 30px;

            .inf-pnl-bg {
                background-color: $lightGrey;
                overflow: hidden;
            }

            .topLeftBorder, .topRightBorder {
                width: 50%;
                height: 15px;
                background-color: shade($lightGrey, 5%);
                display: inline-block;
            }

            .topLeftBorder {
                transform: skew(-60deg);
                transform-origin: top right;
                margin: 0 0 0 4px;
            }

            .topRightBorder {
                transform: skew(60deg);
                margin: 0 -11px 0 0;
            }

            .bottomBorder {
                height: 5px;
                width: 100%;
                background-color: $lightBlue;
            }

            .info-content {
                float: none;
                color: $darkBlue;
                font-weight: 700;
                font-size: 3.0rem;
                @include responsive-font(3.0vw, 18px, 30px);
                display: inline-block;
                line-height: 2.2rem;
                padding: 2.0rem 1.0rem;

                a {
                    color: $darkBlue;

                    &:hover {
                        text-decoration: none;
                    }
                }

                span {
                    width: 100%;
                    height: auto;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}

