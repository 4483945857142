﻿.page-home {
    border-top: 5px solid #0077b3;
    margin: 0 0 30px 0;
    position: relative;

    h1 {
        font-weight: bold;
        color: white;
        /*font-size: 2.6em;*/
        /*line-height: 44px;*/
        margin: 0;
        text-transform: uppercase;

        &.text-center {
            text-align: center;
        }
    }

    p {
        color: white;
        font-size: 19px;
        line-height: 1.5em;
        font-weight: lighter;
        margin: 0;

        &.text-center {
            text-align: center;
        }

        a {
            color: white;
        }
    }

    .position-bottom {
        @media (min-width:40em) {
            position: absolute;
        }

        bottom: 20px;
        left: 20px;
        right: 20px;
    }

    .btn {
        font-size: 18px;
        color: white;
        background: #009cde;
        padding: 10px 30px;
        margin: 10px 0;
        border: 0;
        border-radius: 10px;
        font-weight: lighter;

        &:hover {
            background: shade(#009cde, 15%);
            @include transition(all 0.3s ease-in-out 0s);
        }

        &:focus {
            outline: none;
        }
    }

    .slick-banner-slider {
        width: 100%;
        display: none;

        @media (min-width:64em) {
            display: block;
            float: left;
            min-height: inherit;
        }

        .banner-slide {
            padding: 15px 20px;
            margin: 0;
            background-repeat: no-repeat;
            background: top center;
            display: none;

            &:first-child {
                display: block;
            }
            /*min-height:555px;*/
            @media (min-width:40em) {
                min-height: 555px;
                background-position: top right;
            }

            background-size: cover;

            &.banner-slide-one {
                background-image: url(../../dist/images/header-bg-01.jpg);

                @media (min-width:40em) {
                    background-image: url(../../dist/images/banner-updated-4.jpg);
                }
            }

            &.banner-slide-two {
                background-image: url(../../dist/images/header-bg-01.jpg);

                @media (min-width:40em) {
                    background-image: url(../../dist/images/header-bg-03.jpg);
                }
            }
        }
    }

    .content-position {
        position: relative;

        @media (min-width:64em) {
            position: absolute;
        }

        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;

        .banner-copy {
            width: 100%;
            padding: 0 10px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(../../dist/images/header-bg-01.jpg);

            @media (min-width:64em) {
                width: 68%;
                float: left;
                height: auto;
                background-image: none;
            }

            .slick-copy-slider {
                position: relative;

                @media (min-width:40em) {
                    width: 80%;
                }

                .copy {
                    display: none;

                    &:first-child {
                        display: block;
                    }

                    padding: 50px 15px 50px 0;

                    br {
                        &.hide {
                            display: none !important;

                            @media (min-width:40em) {
                                display: block !important;
                            }
                        }
                    }

                    h1 {
                        margin: 10px 0;
                        line-height: 52px;
                        border-width: 0px;
                        letter-spacing: 0px;
                        /*font-size: 52px;*/
                        @include responsive-font(3vw, 32px, 52px);
                    }

                    .btn {
                        margin: 30px 0;
                    }

                    .scheme-providers {
                        margin: 60px 0 0 0;

                        a {
                            &:hover {
                                text-decoration: none;
                            }

                            &.smaller {
                                font-size: 14px;
                                color: #009cde;
                            }

                            &.fancy-title {
                                font-family: Shadows Into Light,cursive;
                                font-size: 26px;
                                letter-spacing: 1px;
                                color: #009cde;
                            }
                        }
                    }
                }
            }
        }

        .banner-search {
            @media (min-width:64em) {
                float: right;
                width: 32%;
                height: 555px;
                max-height: 555px;
                background: rgba(0, 156, 222, 0.8);
            }
            /*max-width:360px;*/
            background-color: #009cde;
            position: relative;

            .search-content {
                text-align: left;
                padding: 20px;
                position: relative;

                @media (min-width: 64em) {
                    top: 50%;
                    transform: translateY(-50%);
                }

                h1 {
                    /*font-size:24px;*/
                    @include responsive-font(3vw, 18px, 24px);
                    line-height: 27px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 18px;
                    margin: 10px 0;
                }

                a {
                    &.smaller {
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }

                .btn {
                    background: #0077b3;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    &:hover {
                        background: shade(#0077b3, 15%);
                        @include transition(all 0.3s ease-in-out 0s);
                    }
                }

                .input-group {
                    margin: 50px auto 10px auto;
                    width: 100%;

                    input {
                        margin: 10px 0;
                        padding: 10px;
                        height: auto;

                        &.form-control {
                            @include border-radius(10px);

                            &:not(:first-child) {
                                &:not(:last-child) {
                                    @include border-radius(10px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .master-slider {
        &.slick-initialized {
            .banner-slide {
                display: block;
            }

            &.slick-copy-slider {
                .copy {
                    display: block;
                }
            }
        }
    }
}
