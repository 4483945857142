﻿.gold_banner {
    background: #c6aa5d;
    display: table;
    width: 100%;
    padding: 20px 0;

    @media (min-width:64em) {
        text-align: left;
    }

    text-align: center;

    h2 {
        font-size: 30px;
        /*@include responsive-font(4vw, 26px, 46px);*/
        font-weight: bold;
        color: white;

        @media (min-width:64em) {
            margin: 0;
        }

        margin: 0 0 15px 0;
    }

    img {
        max-height: 45px;
        margin: 0 30px 0px 0;
    }
}
