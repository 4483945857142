/* Add here all your CSS customizations */
.radio.complaint input[type='radio'] {
    margin-left: -20px;
}

.course-price {
    background-color: #ecece8;
    padding: 0;
    margin: 0 0 20px 0;
    display: table;

}

.course-price .course-price-title {
    background: #0077b3;
    padding: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
}

.course-price-shell {
    padding: 10px;
    border-top: 5px solid #009cde;
}

.course-price h2 {
    margin: 0;
    font-weight: 600;
    display: inline-block;
}

.course-price p {
    margin: 5px 0 5px 0;
}

.course-price-year-1, .course-price-year-2 {
    margin: 0 0 10px 0;
}

.course-price-call-details {
    padding: 0 0 20px 0;
}

.course-price-call-details a {
    font-weight: bold;
    white-space: nowrap;
}

.course-price-enquiry-btn {
    padding: 0 0 15px 0;
}

.course-price-apply-btn {
    padding: 0 0 10px 0;
}

.course-price .btn {
    width: 100%;
    font-size: 13px;
}

.course-price .btn:hover {
    background: #0077b3;
}

.course-price i.fa {
    border: 2px solid red;
    padding: 5px;
    margin:10px 5px 10px 0;
    border-radius: 50%;
    width: 28px;
    position:relative;
}

.course-price i.fa:before {
    position:relative;
    color: red;
    left: 4px;
}

.course-price i.fa:last-child {
    margin: 10px 5px 0 0;
}

.center-image {
    display: block;
    margin: 3em auto;
}

#xxsIntro h2 {
    text-align: center;
}

.gmap_marker {
    min-width: 170px;
}

.img-responsive.sidebar-news-img {
    max-width: 50px;
}

#txt_search_footer {
    height: 42px;
}

#btn_search_footer {
    height: 41px;
}

.terms-conditions ul li {
    list-style-type: none;
}

.postcode-search-box-small {
    width: 300px;
}

input#txt_search_area_small {
    font-size: 26px;
}

.postcode-search-box {
    width: 700px;
    height: 70px;
}

input#txt_search_area {
    font-size: 30px;
    font-weight: 100;
}

.no-padding {
    padding: 0;
}

@media (max-width: 780px) {
    .col-xxs {
        display: block;
        float: left;
        width: 50%;
    }
}

@media (max-width: 420px) {
    .col-xxs {
        display: block;
        float: none;
        width: 100%;
    }
}

.home-xxs-intro {
    display: none;
}

@media (max-width: 420px) {
    .home-xxs-intro {
        display: block;
    }
}



@media (max-width: 420px) {
    .hidden-xxs {
        display: none;
    }

    .visible-xxs {
        display: block;
    }
}

.home-page-small {
    height: 40px;
}



.home-page-search-btn {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 24px;
    height: 50px;
}

.home-page-search-btn-small {
    padding: 9px 60px;
    margin: 0;
    height: 60px;
    width: 250px;
    font-size: 18px;
    font-weight: bold;
    padding-right: 100px;
}

.thumb-info-title.frank-height {
    bottom: 4%;
}

.navfix {
    padding-bottom: 20px;
}

.tab-content h3 {
    margin-bottom: 10px !important;
    margin-top: 30px !important;
}

.sidebar > h4:nth-child(1) {
    margin-top: 20px;
}

h6 {
    display: inline;
    font-weight: 600;
}

.installer-search h5 {
    margin-bottom: 0 !important;
}

.featured-boxes.login {
    max-width: 50%;
    margin: 0 auto;
}
/* ------------------ Get started Modal error checks -------------------- */
#txt_name_req, #txt_number_req, #txt_postcode_req, #ddl_time_req, #ddl_interest_req, #ddl_day_req, #txt_email_req {
    display: none;
    color: red;
}
/* /Get started Modal error checks */

/* --------------------- Join NAPIT Trade Assoc -------------------------- */
#txt_fullName_check, #txt_membershipNum_check, #txt_email_check, #ddl_council_check, #txt_message_check {
    display: none;
    color: red;
}
/* /Join NAPIT Trade Assoc*/

/*  ------------------ NAPIT SEARCH MODAL SPECIALITIES ------------------- */
.spec-electrician, .spec-heating, .spec-micro, .spec-ventilation, .spec-eicr {
    display: none;
}
/*  /NAPIT SEARCH MODAL SPECIALITIES */


/* ---------------- Success message ---------------- */
.message-success {
    display: none;
    margin-top: 5px;
}
/* /Success message */

/* ----------------- Installer ------------ */
.installer-left-wrapper h3 {
    margin-bottom: 15px;
}

.installer-left-wrapper img {
    display: block;
}

h3.speciality-header {
    margin-top: 25px;
}

.logo-wrapper {
    margin-top: 35px;
}

#map-wrapper, .map-wrapper {
    width: 90%;
    height: 225px;
}
/* /Installer ------------ */


/* ---------------- DISCOUNT Services ------- */
.cta-action {
    position: absolute;
    top: 0;
    background-color: #000000;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    padding: 10px 0;
    color: #fff;
    text-align: center;
    font-weight: bold;
    opacity: 0;
    -moz-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}

a.thumb-info:hover .cta-action {
    opacity: 1;
}

.team-item-logo {
    margin-top: -30px;
}

.calibration-height, .onlineStore-height {
    min-height: 520px;
}

    .calibration-height a:hover {
        cursor: pointer;
    }

.training-height {
    min-height: 451px;
}

.onlineStore-logo {
    position: absolute;
    bottom: 34px;
    left: 54px;
}

.napit-drive-logo {
    margin-bottom: 20px;
}

/* /DISCOUNT Services ------- */


/* ------------------ HELP & SUPPORT ----------- */

.forum-img-logo {
    margin-top: 49px;
}
/* /HELP & SUPPORT ----------- */

/* ------------------  PROMOTION & MARKETING ----------- */
.promotion-height {
    min-height: 477px;
}

.online-height {
    min-height: 450px;
}

.nation-height {
    min-height: 449px;
}

.events-height {
    min-height: 487px;
}

.industry-height {
    min-height: 477px;
}

.expo-height {
    min-height: 489px;
}

.legal-height {
    min-height: 513px;
}

.legal-height-logo {
    position: absolute;
    bottom: 14px;
    left: 20%;
}

.forum-height {
    min-height: 459px;
}

.lawAdvice-height, .quality-guarantee-height {
    min-height: 577px;
}

.promotion-expo-logo {
    margin-left: 8px;
}
/* /PROMOTION & MARKETING ----------- */

/* ----- SCHEMES KEY FOR TABLES --------- */
.table-key {
    border: 1px solid #dddddd;
    padding: 20px;
}

    .table-key p {
        font-size: 80%;
    }

        .table-key p span {
            padding: 8px 10px;
            font-size: 14px;
            font-weight: bold;
        }

        .table-key p a {
            text-decoration: none;
        }

/* ----- /SCHEMES KEY FOR TABLES --------- */

/* -------------- HomeOwner --------- */

ul.homeOwner li {
    list-style-type: none;
    padding: 4px 0
}
.homeOwner li strong {
    margin-right: 10px;
}
.homeOwner li i {
    color: #009cde; 
}
.img-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
/* -------------- /HomeOwner -------- */

.slideVidFix {
    margin-top: 170px;
}

.no-pad {
    padding: 0;
}

.pas-table {
    margin-top: 32px;
}

.po-verify {
    margin-left: 10px;
    margin-top: 50px;
}

.po-verify-disp {
    margin-top: 29px;
}

.radio input[type="radio"] {
    margin-left: 0;
}

.bg-info {
    padding: 15px;
}

.notify-row-1 {
    width: 80%;
}

.blue-italics {
    font-family: "Shadows Into Light", cursive;
    font-size: 1.6em;
    font-style: italic;
    color: #00aaff;
}

.blue-italics-sm {
    font-family: "Shadows Into Light", cursive;
    font-size: 1.2em;
    font-style: italic;
    padding-top: 3px;
    color: #00aaff;
    margin-top: 3px;
}

.homeSlideSearchBox {
    width: 730px;
    height: 60px;
    background-color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 0;
    color: rgba(80, 80, 80, 0);
}

.homeSlideSearchBoxSm {
    width: 690px;
    height: 60px;
    background-color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 0;
    color: rgba(80, 80, 80, 0);
}

.home-page-search {
    height: 60px;
    margin: 0;
    width: 100%;
    background-color: transparent;
    border-style: none;
    border-color: transparent;
    padding: 0;
    margin-bottom: 10px;
    color: #000;
    padding-left: 10px;
}

    .home-page-search::-webkit-input-placeholder {
        color: #000;
        padding-top: 7px;
    }

    .home-page-search::-moz-placeholder {
        color: #000;
        padding-top: 7px;
    }

    .home-page-search::-ms-input-placeholder {
        color: #000;
        padding-top: 7px;
    }

.bigger-lineheight {
    line-height: 28px;
}

.sliderSmallGray, .sliderSmallGray a:link, .sliderSmallGray a:active, .sliderSmallGray a:visited, .sliderSmallGray a:hover {
    color: #999;
    font-weight: 300;
}

.home-intro2 {
    margin-bottom: 60px;
}

.xsslidebtnfix {
    right: 20px !important;
}

.slider-container .main-xs-label {
    color: #FFF;
    font-size: 92px;
    font-weight: 800;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.slider-container .bottom-xs-label {
    color: #FFF;
    font-size: 36px;
    line-height: 40px;
    font-weight: 300;
}

.slider-container .top-label-xs {
    color: #FFF;
    font-size: 32px;
    font-weight: 300;
}

.slider-container .bottom-label {
    color: #FFF;
    font-size: 20px;
    font-weight: 300;
}

footer .container p {
    color: #c3c3c3;
}

#footer.short .footer-copyright p {
    padding-top: 5px;
    padding-left: 25px;
}

#footer .footer-copyright p.privacy {
    padding-left: 0;
}

.breadcrumb a {
    color: #FFF;
    font-weight: 400;
}

.breadcrumb li.active {
    color: #c3c3c3;
}

.aboutCarousel p {
    color: #e9e9e9;
}

ul.team-list .team-item {
    margin-bottom: 10px;
}

.videoabout {
    margin: 0;
    margin-top: 70px;
    margin-bottom: 15px !important;
}

ul.nav-list > li.active > a {
    background-color: #CCC;
}

.dxeTextBox_iOS, .dxeButtonEdit_iOS, .dxeMemo_iOS {
    border-color: #ccc !important;
}

.dxbButton_iOS {
    font-size: 18px !important;
    font-family: "Open Sans", Arial, sans-serif;
}

    .dxbButton_iOS div.dxb {
        padding: 0;
    }

.dxeTextBox_iOS .dxeEditArea_iOS, .dxeButtonEdit_iOS .dxeEditArea_iOS, .dxeMemoEditArea_iOS {
    color: #a1a1a1;
}

.dxeErrorCell_iOS {
    position: absolute;
    right: 15px;
    top: 40px;
}

.breadcrumblight {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent !important;
}


.zeromargin {
    margin: 0 !important;
}

.featureddark {
    background-image: url('/img/slides/slide-bg-vid.jpg') !important;
    padding: 0 !important;
    padding-top: 20px !important;
}

.leadlight {
    color: azure !important;
}

.talllight {
    color: #c4c4c4;
}

.scheme-text {
    font-weight: 600;
    color: #333;
}

/*#header nav.mega-menu ul.nav-main .mega-menu-whyNapit .dropdown-menu {
    width: 46%;
    left: 19%;
}*/

/*#header nav.mega-menu ul.nav-main .mega-menu-schemes .dropdown-menu {
    width: 54%;
    left: 25%;
    -moz-border-radius: 6px 0 6px 6px;
    -webkit-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}*/

.text-small {
    font-size: 20px;
}

.alt-row {
    background-color: #f6f6f6;
}

.mcs-bg-color, .key-mcs {
    background-color: #2bb04a;
    color: #fff;
    text-align: center;
}

.gd-bg-color, .key-eef {
    background-color: #9ccb3b;
    color: #fff;
    text-align: center;
}

.cps-bg-color, .key-cps {
    background-color: #ed1c24;
    color: #fff;
    text-align: center;
}

.eas-bg-color, .key-electro {
    background-color: #000000;
    color: #fff;
    text-align: center;
}

.tm-bg-color, .key-tm {
    background-color: #009cde;
    color: #fff;
    text-align: center;
}

.tp-bg-color, .key-tps {
    background-color: #f6881f;
    color: #fff;
    text-align: center;
}

.eis-bg-color, .key-eis {
    background-color: #3E4543;
    color: #fff;
    text-align: center;
}

.tei-bg-color, .key-tei {
    background-color: #a99f9f;
    color: #fff;
    text-align: center;
}

.orange-bg-color {
    background-color: #F7A85C;
    text-align: center;
}

.nap-bg-color, .key-test {
    background-color: rgb(0, 83, 159);
    color: #fff;
    text-align: center;
}

.pink-bg-color {
    background-color: pink;
    text-align: center;
}

.grey-bg-color {
    background-color: lightgray;
    text-align: center;
}

.green-bg-color {
    background-color: lightgreen;
    text-align: center;
}

.blue-bg-color {
    background-color: lightskyblue;
    text-align: center;
}

.lg-green-bg-color {
    background-color: #b5d27a;
    text-align: center;
}

.scheme-image {
    margin-left: 15px;
    margin-bottom: 15px;
}

.scheme-add-btn {
    float: right;
    margin: 50px 15px;
    margin-left: 100px;
}

.trade-col {
    margin: 0 !important;
    padding: 0 !important;
}

#byTradeNav > li {
    float: none;
    display: inline-block;
    *display: inline; /* ie7 fix */
    -ms-zoom: 1;
    zoom: 1; /* hasLayout ie7 trigger */
}

#scheme-stats {
    margin-top: 10px;
}

.white {
    color: #c3c3c3;
}

.parallax-wrapper {
    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkRDMDBGQ0Y5NEI4NjExRTM5RTg2QTY1RTUyQjIxNEQyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkRDMDBGQ0ZBNEI4NjExRTM5RTg2QTY1RTUyQjIxNEQyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6REMwMEZDRjc0Qjg2MTFFMzlFODZBNjVFNTJCMjE0RDIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6REMwMEZDRjg0Qjg2MTFFMzlFODZBNjVFNTJCMjE0RDIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6C0EmkAAAAGElEQVR42mJiYGD4DwQMTECCEchhAAgwAEcGBv9dngKPAAAAAElFTkSuQmCC') /*../img/video-overlay.png*/ repeat 0 0;
}


.thumb-info-title-full {
    width: 100%;
}

.thumb-info-type-centre {
    float: none !important;
    position: absolute !important;
    top: 55px;
    left: 30%;
}

#NewsTags a {
    margin-right: 5px;
}

    #NewsTags a:link, #NewsTags a:hover, #NewsTags a:visited, #NewsTags a:active {
        text-decoration: none;
    }


.cta-fix h3 {
    line-height: 46px;
}
/*  --------------------- Student Scheme ---------------- */
.student-promo {
    background-color: #009cde;
    padding: 20px;
    border-radius: 5px;
    color: #fff;
}

    .student-promo > h4 {
        color: #fff;
    }

.student-promo-list {
    margin-left: 20px;
    list-style-type: disc;
}

/*-- -Apprenticeship page --*/
.student-promo.apprentice {
    background-color: transparent;
    border: solid 1px #ccc;
}

    .student-promo.apprentice p {
        text-align: center;
    }

/*  --------------------- /Student Scheme ---------------- */
/*  --------------------  MEMBER DOWNLOADS */
.pdfs tr > td {
    width: 80%;
}
/*  -------------------  /MEMBER DOWNLOADS */

/* ----------------- TRADE ASSOCIATION ABOUT */
.campaign-row {
    margin-top: 20px;
}


    .campaign-row a img {
        margin-right: 3%;
    }

    .campaign-row img.first-campaign {
        margin-left: 20px;
    }

    .campaign-row img.last-campaign {
        margin-right: 0;
    }

.nav-tabs > li > a.esr-padding {
    padding-left: 25px;
    padding-right: 25px;
}

@media only screen and (max-width: 1200px) {
    .campaign-row img {
        margin-top: 20px;
    }

        .campaign-row img.first-campaign {
            margin-left: 0;
        }
}
/* ---------------- /TRADE ASSOCIATION ABOUT */

/*  --------------- TRADE MEETING CALENDAR */
.meeting-row {
    margin: 20px 0;
}

.widget-summary.widget-summary-xlg {
    padding: 5px 0;
}

.widget-summary {
    display: table;
    width: 100%;
}

    .widget-summary .widget-summary-col.widget-summary-col-icon {
        width: 1%;
    }

    .widget-summary .widget-summary-col {
        display: table-cell;
        vertical-align: top;
        width: 100%;
    }

    .widget-summary .summary-icon {
        margin-right: 15px;
        font-size: 20px;
        font-size: 2rem;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        -webkit-border-radius: 55px;
        -moz-border-radius: 55px;
        border-radius: 55px;
    }

    .widget-summary .summary .title {
        margin: 0;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 22px;
        line-height: 2.2rem;
        color: #333333;
        font-weight: 500;
    }

    .widget-summary .summary-footer {
        padding: 5px 0 0;
        border-top: 1px dotted #ddd;
        text-align: right;
    }

/*  NEWS ARTICLE */
article .post-content > h2 {
    color: #00aaff;
    margin-bottom: 22px;
}

.paging-btns li {
    list-style: none;
    padding: 0 15px;
}
/*  /NEWS ARTICLE */

/* --------------------- EVENTS CALENDAR ------------------ */
.january, .february,.march, .april, .may, .june, .july, .september, .october, .november, .december {
    display: none;
}

.eventCal {
    display: block;
}

.nMonth {
    visibility: hidden;
}

.top-pagination {
    margin-bottom: -45px;
}

    .top-pagination li {
        list-style-type: none;
    }

        .top-pagination li:first-child {
            margin-right: 10px;
        }

.events-calendar li {
    list-style-type: none;
    margin-right: 10px;
}

.newsNext, .newsPrev {
    cursor: pointer;
}
.btn-expo {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 10px;
}
/* /EVENTS CALENDAR */

/* ------------------------- TIMELINE ----------------------- */
.timeline .tm-body {
    position: relative;
    margin-top: 30px;
}

    .timeline .tm-body:after {
        background: #505050;
        background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
        background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
        background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
        background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
        content: '';
        display: block;
        height: 100%;
        left: 170px;
        margin-left: -2px;
        position: absolute;
        top: 0;
        width: 3px;
        z-index: 0;
        filter: alpha(opacity=35);
        opacity: 0.35;
    }

.timeline .tm-title {
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 200px;
    background-color: #cccccc;
    padding: 4px 5px;
    margin: 0 70px;
    z-index: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.timeline .tm-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

    .timeline .tm-items > li {
        position: relative;
        margin: 30px 0;
        padding: 0 0 0 210px;
        min-height: 65px;
        z-index: 1;
    }

        .timeline .tm-items > li .tm-datetime {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100px;
            height: 48px;
            margin-top: -24px;
            text-align: right;
            z-index: 3;
        }

            .timeline .tm-items > li .tm-datetime .tm-datetime-time {
                color: #009cde;
                font-size: 24px;
                font-size: 2.4rem;
                font-weight: 700;
                margin: 0;
                white-space: nowrap;
            }

        .timeline .tm-items > li .tm-icon {
            position: absolute;
            top: 50%;
            left: 170px;
            background-color: rgb(255, 255, 255);
            border: 3px solid #009cde;
            color: #009cde;
            font-size: 28px;
            padding: 10px;
            width: 55px;
            height: 55px;
            text-align: center;
            line-height: 29px;
            margin-top: -28px;
            margin-left: -28px;
            z-index: 2;
            -webkit-border-radius: 28px;
            -moz-border-radius: 28px;
            border-radius: 28px;
        }

        .timeline .tm-items > li .tm-box {
            position: relative;
            background: #fff;
            min-height: 65px;
            padding: 10px 20px;
            border: 1px solid #e9e9e9;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
        }

            .timeline .tm-items > li .tm-box:after {
                right: 100%;
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-right-color: #fff;
                border-width: 8px;
                top: 50%;
                margin-top: -8px;
                z-index: 2;
            }

            .timeline .tm-items > li .tm-box p:last-child {
                margin-bottom: 0;
            }

            .timeline .tm-items > li .tm-box .tm-meta {
                margin: 10px 0 0;
            }

                .timeline .tm-items > li .tm-box .tm-meta span {
                    display: inline-block;
                    padding-right: 8px;
                }

                    .timeline .tm-items > li .tm-box .tm-meta span:last-child, .timeline .tm-items > li .tm-box .tm-meta span:last-of-type {
                        padding-right: 0;
                    }

.timeline.timeline-simple .tm-body:after {
    left: 30px;
}

.timeline.timeline-simple .tm-body .tm-title {
    border: 1px solid #e9e9e9;
    margin: 0 10px;
}

.timeline.timeline-simple .tm-body .tm-items > li {
    padding: 0 0 0 55px;
}

    .timeline.timeline-simple .tm-body .tm-items > li:before {
        display: block;
        position: absolute;
        content: ' ';
        background: none repeat scroll 0 0 #cccccc;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-box-shadow: 0 0 0 3px white, 0 0 0 6px #cccccc;
        -webkit-box-shadow: 0 0 0 3px white, 0 0 0 6px #cccccc;
        box-shadow: 0 0 0 3px white, 0 0 0 6px #cccccc;
        height: 7px;
        left: 30px;
        top: 50%;
        width: 8px;
        margin-left: -4px;
        margin-top: -4px;
    }

    .timeline.timeline-simple .tm-body .tm-items > li .tm-box:before {
        left: -17px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: #e9e9e9;
        border-width: 8px;
        top: 50%;
        margin-top: -8px;
        z-index: 1;
    }

#paging {
    display: table;
    margin: 0 auto;
}

#ASPxCloudControl1 > tbody:nth-child(1) > tr:nth-child(1) > td:nth-child(1) {
    margin: 0;
    padding: 0;
    padding-bottom: 45px;
}

.dxccLink {
    text-align: left !important;
}

#ASPxCloudControl1 td {
    text-align: left !important;
}


/* ------------------- /TIMELINE ------------------------ */
/* Nice Media Queries */

@media only screen and (min-width: 480px) {
    /* TRADE MEETING CALENDAR */
    .widget-summary.widget-summary-xlg .summary .title {
        font-size: 20px;
        font-size: 2rem;
        line-height: 28px;
        line-height: 2.8rem;
    }

    .widget-summary.widget-summary-xlg .summary-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        font-size: 2rem;
    }
    /* /TRADE MEETING CALENDAR */
}

.download-table {
    margin-left: 8px;
    margin-right: 10px;
    width: 95%;
}

.privacy {
    color: #c3c3c3;
    font-weight: 400;
}
    .privacy a.aboutUsLink {
        display: none;
    }



/* ************************   INSTALLER SEARCH **************************/


.searchPageBarTop {
    border-top: 5px solid #ccc;
    margin-bottom: 15px;
}

.searchBox {
    width: 400px;
    background-color: rgba(255,255,255,0.8);
    height: 350px;
    position: absolute;
    bottom: 510px;
    right: 0;
    z-index: 999;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

.margin-lft-5 {
    margin-left: 5px;
}

.margin-lft-0 {
    margin-left: 0;
    padding-left: 0 !important;
}

.search-top {
    margin-bottom: 0;
}


.margin-rt-0 {
    margin-right: 0 !important;
}

.form-group-searchbar {
    padding-top: 0;
}

.form-label-search {
    color: #009cde;
    font-family: "Shadows Into Light", cursive;
    font-size: 2em;
    float: left;
    padding-top: 8px;
    margin-right: 10px;
}

.margin-btm-0 {
    margin-bottom: 0 !important;
}

.margin-top-20 {
    margin-top: 20px;
}

#pnlResults {
    margin-top: 25px;
}

.google-map-installer-search {
    margin-top: 80px;
    height: 420px;
    z-index: 2;
    margin-bottom: 0;
    margin-bottom: 30px;
}

.form-field-searchbar {
    float: left;
    width: 67%;
}

.form-label-search-2nd {
    margin-left: 15px;
}

.searchSchemeTick {
    float: left;
}

.searchSchemeContainer {
    float: left;
    margin-right: 30px;
}

.searchSchemeLogo {
    float: left;
    padding-top: 15px;
    font-size: 1.2em;
    font-weight: 300;
}

.searchSchemeLogoAdv {
    float: left;
    padding-top: 15px;
    font-size: 1em;
    font-weight: 300;
}

#advancedText {
    display: none;
}

.installerLogoTick {
    float: left;
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
    background-image: url('/img/installer/tick.png');
    background-repeat: no-repeat;
    clear: both;
    margin-left: 15px;
}

.installerLogoText {
    display: block;
    float: left;
    font-size: 1.2em;
    font-weight: 300;
    padding-top: 3px;
    padding-left: 15px;
}

.schemeSelectionList {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.modalFront {
    float: left;
}

.schemeSelectionItem {
    width: 280px;
}

.searchButton {
    margin-top: 28px;
}

.searchModalTrades {
    padding: 20px 20px;
}

.member-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.member-item {
    width: 300px;
}

.address-wrapper p {
    margin-bottom: 10px;
}

.blue-highlight {
    color: #009cde;
}

.margin-btm-30 {
    margin-bottom: 30px;
}

.search-or {
    text-align: center;
    margin-top: 40px;
    font-size: 2.3em;
}

@media (max-width: 768px) {
    .searchButton {
        margin-top: 0;
    }
    .container {
        margin-bottom: 20px;
    }
}


.searchSchemeLogo img {
    float: left;
    padding-top: 5px;
    max-width: 300px;
    width: 75%;
}

.site-page-nav {
    margin-bottom: 25px;
}


.formFieldEnabled {
    border-color: #CCC !important;
}

.formFieldDisabled {
    border-color: #f4f4f4 !important;
    color: #f4f4f4;
}

.hiddenLabel label {
    color: #f4f4f4;
}

/*  JOIN NAPIT Route to membership   */
#experienced ul li {
    list-style: none;
    margin: 15px 0;
}

    #experienced ul li i {
        font-size: 16px;
        color: #019CDE;
    }
/*  TRADE ASSOCIATION trade assoc.aspx  */
.trade-tabs {
    margin-top: 30px;
}

.dark-member {
    color: #525252;
}

/*-- careers page --*/
#currentVacancies {
    margin-bottom: 20px;
}

.vacancies {
    padding-bottom: 30px;
    padding-left: 15px;
}

    .vacancies div, .vacTblSpan, .vacTblSpan strong {
        padding-left: 0;
    }

/*.hidden-xs {
    display: inline-block;
}*/

.vcTitle {
    font-weight: 700;
    width: 33.33333333%;
}

.vcDetail {
    width: 66.66666667%;
}

.jobSpec {
    margin: 10px 0 0;
    padding-left: 40px;
}


.yellow-covid {
    background-color: #fae805;
    color: #525252;
}
.yellow-covid:hover{
    color: #ffffff;
}

@media (max-width: 575px) {
    .vcTitle { /*font-weight: 700;*/
        width: 100%;
    }

    .vcDetail {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .jobVacancy {
        padding: 0;
    }

    .hidden-xs {
        display: none;
    }
}

@media (min-width: 992px) {
    .vacancyTbl {
        width: 75%;
    }
}

@media (min-width: 1210px) {
    .vacancyTbl {
        width: 65%;
    }
}
@media only screen and (min-width: 992px) {
    
     .course-price {
        display: block;
    }

}

@media only screen and (max-width: 52em) {
    .tablet-sidebar {
        margin-top: 1rem;
    }

    h3.speciality-header {
        margin-top: 0;
    }

    .course-price {
        margin: 0 15px 20px 15px;
        text-align: center;
        display: table;
    }

    .course-price-apply-btn {
        float: left;
        width: 48%;
        margin: 0 1%;
    }

     /*.course-price-apply-btn:nth-of-type(6) {
        float: left;
        width: 98%;
        margin:0 1%;
    }*/

    .course-price-year-1, .course-price-year-2{
        float: left;
        width: 50%;
    }
}

@media (max-width: 600px){
    .privacy a.aboutUsLink {
        display: inline-block;
    }
}

@media only screen and (max-width: 40em) {
    .tablet-sidebar {
        margin-top: 1rem;
    }

    h3.speciality-header {
        margin-top: 0;
    }

    .course-price {
        margin: 0 0 20px 0;
        text-align: center;

    }

    .course-price-apply-btn {
        float: left;
        width: 100%;
        margin: 0;
    }

    .course-price-apply-btn:nth-of-type(8) {
        float: left;
        width: 100%;
        margin:0;
    }

    .course-price-year-1, .course-price-year-2{
        margin: 0 0 20px 0;
        width: 100%;
    }
}


@media only screen and (max-width: 1199px) {
    .benefits-btn {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1380px) {
    .video-padding {
        padding: 40px 0;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 4.3%;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 5.4%;
    }
}

@media (min-width: 1600px) and (max-width: 1700px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 6.1%;
    }
}

@media (min-width: 1700px) and (max-width: 1730px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 6.5%;
    }
}

@media (min-width: 1730px) and (max-width: 1800px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 7.5%;
    }
}

@media (min-width: 1800px) and (max-width: 1930px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 8.3%;
    }
}

@media (min-width: 1930px) and (max-width: 2500px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 9.1%;
    }
}

@media (min-width: 2500px) and (max-width: 4500px) {
    .slider-container .tp-caption.fullscreenvideo video {
        margin-top: 10.2%;
    }
}


