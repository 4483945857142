// main.scss


/* Combined CSS ordered from Masterpage as shown
1. vendor/bootstrap.min
2. vendor/Fontawsome.min
3. vendor/owlcarousel.min
4. vendor/owlthemedefault.min
5. vendor/magnificPopup.min
6. vendor/select2
All saved as one file in vendor directory as they reference `Things relative to that directory`
*/

@import 'bootstrap';
@import 'html5';
@import 'owl.carousel';
@import 'owl.theme.default';
@import 'magnific-popup';
@import 'select2';

/* 
    All css files in order as displayed in the master page for `theme css`
    1. css/theme.min.css
    2. css/theme-elements.min.css
    3. css/theme-blog.min.css
    4. css/theme-shop.min.css
    5. css/theme-animate.min.css
    6. css/hover-min.css
    7. css/side-switch.css
    8. css/skins/default.min.css
    9. css/custom.min.css
*/

@import 'theme';
@import 'theme-elements';
@import 'theme-blog';
@import 'theme-shop';
@import 'theme-animate';
@import 'hover-min';
@import 'side-switch';
@import 'skins/default';
@import 'custom';


// @import 'datepicker';
// @import 'ie';
// @import 'test';
// @import 'theme - Copy';
// @import 'themeadmin';
// @import 'toastr.min';
// @import 'travelers';

//
@import 'utils/module';

//SCSS Architecture  
@import 'vendors/module';
@import 'components/module';
@import 'layout/module';
@import 'pages/module';