﻿*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//Basic Button - e.g. @include basic-button(#000000, #ff0000);
@mixin basic-button($color, $color-text-hover: $color-light) {
    color: $color;
    border-color: $color;
    background-color: transparent;    
    &:hover {
        color: $color-text-hover;
        background-color: $color;
    }
}

/// Slightly lighten a color - e.g. color: tint(#BADA55, 42%);
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color - e.g. color: shade(#663399, 42%);
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

//Basic Transition Mixin - e.g. @include transition(color .3s ease);
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

// -webkit-text-size-adjust: 100%;
//Webkit Text Size Adjustment - e.g. @include text-size-adjust(100%);
@mixin text-size-adjust($value) {
  -webkit-text-size-adjust: $value; 
  -moz-text-size-adjust: $value;
  -ms-text-size-adjust: $value; 
}

//Box Shadow - e.g. @include box-shadow(inset, 0, 1px, 1px, rgba(0, 0, 0, 0.5));
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}

//Border Box
@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
}     

//Border Radius - e.g. @include border-radius(10px);
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

//User select - e.g. @include user-select(none);
@mixin user-select($outcome){
    -webkit-user-select: $outcome; /* Chrome/Safari */        
  -moz-user-select: $outcome; /* Firefox */
  -ms-user-select: $outcome; /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: $outcome;
  user-select: $outcome;
}

//Clearfix
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

    //Clearfix - examples
    .container-with-floated-children {
        @extend %clearfix;
    }

//Email placeholder styles
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

//Set a rem font size with pixel fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

//Set a rem font size with pixel fallback - examples
/*p {
    @include font-size(14px)
}*/

//Responsive font sizes
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;
  
  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }
  
  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;
    
    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }
  
  @if $fallback {
    font-size: $fallback;
  }
  
  font-size: $responsive;
}

  //Responsive font sizes - examples
  .no-limits {
        font-size: 3vw;
  }

  .limit-min {
        @include responsive-font(3vw, 13px);
  }

  .limit-min-max {
        @include responsive-font(3vw, 20px, 40px);
  }